.container {
  display: flex;
  padding: 30px;
  flex-direction: column;
  border-radius: 5px;
}

.heading {
  background-color: #f6f8f9;
  height: 100px;
  display: flex;
  align-items: center;
  font-size: 40px;
  color: #41566E;
  padding-left: 30px;
  opacity: .9;
  justify-content: space-between;
  border-radius: 5px;

}


.containerPerson {
  display: flex;
  background-color: #f8f9f6c1;
  height: 100%;
  margin-top: 60px;
  flex-direction: column;
  font-size: 20px;
  padding-left: 20px;
  border-radius: 5px;
}

.person {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.gifHeading {
  height: 100px;
  margin-right: 5px;
}

.containetext {
  display: flex;
  align-items: center;
  color: #4d5669;
  font-style: italic;
  font-size: 40px;
}

.containerText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  color: #41566E;
  font-size: 25px;
}

@media screen and (max-width: 426px) {

  .heading {
    font-size: 20px;
  }

  .container {
    padding: 20px;
  }

  .containetext {
    font-size: 20px;
  }

  .containerText {
    font-size: 15px;
    margin-right: 0px;
    display: block;
  }

}